import axios from 'axios'

const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
})

instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.method = "post";

// Add a response interceptor
instance.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, function (error) {


    console.log('URL ==> '+error.config.url);
    console.log('status ==> '+ error.response?.status);
    if(parseInt(error.response?.status) === 401 ) {

        window.location = '/';
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
});


export default instance