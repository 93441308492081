import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

//import Cookies from 'js-cookie'

// import axios from 'axios'

Vue.use(Vuex);
/*
const vCookie = new VuexPersistence({
    storage: {
        getItem: key => Cookies.get(key),
        setItem: (key, value) => Cookies.set(key, value, { expires: 4, secure: false }),
        removeItem: key => Cookies.remove(key)
    }
})
*/

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

const state = {
    page: 0,
    busy: false,
    error: [],
    success: [],
    warning: [],
    current_page: '',
    current_page_name: '',
    previous_page: '',
    current_sub_page: '',
    is_busy:[],
    app: false,
    sms: [],
    worksheetpaymentschedule: {}
};

export default new Vuex.Store({
    state,
    getters: {
        app:  function (state){

            return state.app;

        },
        is_busy: function (state){

            return state.is_busy
        },
        error: function (state){
            return state.error
        },
        success: function (state){
            return state.success
        },
        warning: function (state){
            return state.warning
        },
        current_page: function (state) {

            return state.current_page;
        },
        previous_page: function (state) {

            return state.previous_page;
        },
        current_page_name: function (state) {

            return state.current_page_name;
        },
        current_sub_page: function (state) {

            return state.current_sub_page;
        },
        sms: function (state) {

            return state.sms;
        },
        worksheetpaymentschedule: function (state) {

            return state.worksheetpaymentschedule;
        },
    },
    mutations: {
        setApp: function(state,app){

            state.app = app

        },
        setIsBusy: function(state,is_busy){

            state.is_busy = is_busy

        },
        setError: function(state,payload){

            //payload.arrival_time = new Date().getTime(); // in milisecconds
            state.error = payload;
        },
        setSuccess: function(state,payload){

            state.success = payload;

        },
        setWarning: function(state,payload){

            state.warning = payload; //.push(payload);

        },
        setBusy: function(state,busy){

            state.busy = busy
        },

        setCurrentPage: function(state,current_page){

            state.current_page = current_page
        },

        setCurrentPageName: function(state,current_page_name){

            state.current_page_name = current_page_name
        },

        setPreviousPage: function(state,previous_page){

            state.previous_page = previous_page
        },
        setCurrentSubPage: function(state,current_sub_page){

            state.current_sub_page = current_sub_page
        },
        setSMS: function(state,sms){

            state.sms = sms
        },
        setWorksheetPaymentSchedule: function(state,worksheetpaymentschedule){

            state.worksheetpaymentschedule = worksheetpaymentschedule
        },
    },
    actions: {
        setApp: function({commit},app){

            commit('setApp',app)

        },
        setIsBusy: function({commit},is_busy){
            commit('setIsBusy',is_busy)
        },
        setError: function ({commit},payload) {

            payload.arrival_time = new Date().getTime(); // in milisecconds
            var all = state.error;
            all.push(payload);
            commit('setError',all)

        },
        removeError: function ({commit},index) {

            var all = state.error;
            all = all.splice(index);
            commit('setError',all)
        },
        setWarning: function ({commit},payload) {

            payload.arrival_time = new Date().getTime(); // in milisecconds
            var all = state.warning;
            all.push(payload);
            commit('setWarning',all)
        },
        removeWarning: function ({commit},index) {

            var all = state.warning;
            all = all.splice(index);
            commit('setWarning',all);
        },
        setSuccess: function ({commit},success) {

            success.arrival_time = new Date().getTime(); // in milisecconds
            var all_success = state.success;
            all_success.push(success);

            commit('setSuccess',all_success)
        },
        removeSuccess: function ({commit},index) {

            var all = state.success;
            all = all.splice(index);
            commit('setSuccess',all);
        },
        resetAlerts: function ({commit}) {

            commit('resetAlerts')
        },
        setCurrentPage: function ({commit},current_page) {

            commit('setCurrentPage',current_page)
        },
        setCurrentPageName: function ({commit},current_page_name) {

            commit('setCurrentPageName',current_page_name)
        },
        setPreviousPage: function ({commit},previous_page) {

            commit('setPreviousPage',previous_page)
        },
        setCurrentSubPage: function ({commit},current_page) {

            commit('setCurrentSubPage',current_page)
        },
        setSMS: function ({commit},sms) {

            commit('setSMS',sms)
        },
        setWorksheetPaymentSchedule: function ({commit},worksheetpaymentschedule) {

            commit('setWorksheetPaymentSchedule',worksheetpaymentschedule)
        },
    },
    plugins: [vuexLocal.plugin]
})