<template>

    <div class="">
      <div class="container-fluid cards-section pt-3">
        <div class="row mb-3">
          <div class="col-md-3">
            <div class="dashboard-card p-2 d-flex justify-content-between bg-light">
              <div class="dashboard-card-icon">
                <img src="/img/business.svg" class="dashboard-card-image bg-orange">
              </div>
              <div class="text-right line-height-1 pt-2">
                <span class="text-light-blue">Ksh <strong v-text="formatCurrency(summary.total)"></strong> </span> <br>
                <span class="text-small">Total Payments Made</span>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="dashboard-card p-2 d-flex justify-content-between bg-light">
              <div class="dashboard-card-icon">
                <img src="/img/business.svg" class="dashboard-card-image bg-green">
              </div>
              <div class="text-right line-height-1 pt-2">
                <span class="text-light-blue">Ksh <span v-text="formatCurrency(summary.completed)"></span> <strong>Complete</strong> </span> <br>
                <span class="text-small">Complete Payments</span>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="dashboard-card p-2 d-flex justify-content-between bg-light">
              <div class="dashboard-card-icon">
                <img src="/img/business.svg" class="dashboard-card-image bg-red">
              </div>
              <div class="text-right line-height-1 pt-2">
                <span class="text-light-blue">Ksh <strong v-text="formatCurrency(summary.pending)"></strong> </span> <br>
                <span class="text-small">Pending Payments</span>
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="dashboard-card p-2 d-flex justify-content-between bg-light">
              <div class="dashboard-card-icon">
                <img src="/img/business.svg" class="dashboard-card-image bg-purple">
              </div>
              <div class="text-right line-height-1 pt-2">
                <span class="text-light-blue">Ksh <strong v-text="formatCurrency(summary.partial)"></strong> </span> <br>
                <span class="text-small">Partial Payments</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid cards-section">
        <div class="row mb-3">
          <div class="col-md-6">
            <div class="dashboard-card p-2 d-flex justify-content-between bg-light">
              <div class="dashboard-card-icon d-flex">

                <img src="/img/business.svg" class="dashboard-card-image bg-light-blue">
                <div class=" line-height-1 pt-2">
                  <span class="text-light-blue">Latest <strong>Attendance</strong> </span> <br>
                  <span class="text-small">Per Day</span>
                </div>
              </div>
              <div class="text-right line-height-1 pt-2">
                <span class="text-light-blue"><strong>105</strong> </span>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="dashboard-card p-2 d-flex justify-content-between bg-light">
              <div class="dashboard-card-icon d-flex">

                <img src="/img/business.svg" class="dashboard-card-image bg-light-blue">
                <div class=" line-height-1 pt-2">
                  <span class="text-light-blue">Latest <strong>Attendance</strong> </span> <br>
                  <span class="text-small">Per Day</span>
                </div>
              </div>
              <div class="text-right line-height-1 pt-2">
                <span class="text-light-blue"><strong>105</strong> </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid table-snapshot">
        <div class="row">
          <div class="col-md-6">
            <div class="card custom-card ">
              <div class="card-header text-light">
                Latest <strong>Worksheets (open) </strong>  <a href="/worksheets" class="text-small text-light-blue px-3"> View All </a>
              </div>
              <div class="card-body p-0">

                <table class="table table-striped">
                  <thead class=" ">
                  <tr>
                    <th>Name</th>
                    <th>Start</th>
                    <th>End</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(group,index) in openWorksheet" v-bind:key="getKey(index)"  @click="viewMembers(group)">
                    <td v-text="group.name"></td>
                    <td v-text="formatDate(group.start_date)"></td>
                    <td v-text="formatDate(group.end_date)"></td>
                  </tr>
                  </tbody>
                </table>

              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card custom-card ">
              <div class="card-header text-light">
                Latest <strong>Worksheets (closed) </strong>  <a href="/worksheets" class="text-small text-light-blue px-3"> View All </a>
              </div>
              <div class="card-body p-0">
                <table class="table table-striped">
                  <thead class=" ">
                  <tr>
                    <th>Name</th>
                    <th>Start</th>
                    <th>End</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(group,index) in closedWorksheet" v-bind:key="getKey(index)"  @click="viewMembers(group)">
                    <td v-text="group.name"></td>
                    <td v-text="formatDate(group.start_date)"></td>
                    <td v-text="formatDate(group.end_date)"></td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

</template>

<script>
import axios from "../services/api";

export default {
  name: 'Home',
  components: {

  },
  data: function (){

    return {
      summary: {
        completed: 0,
        pending: 0,
        total: 0,
        partial: 0,
      },
      closedWorksheet: [],
      openWorksheet: [],
    }

  },
  mounted: function () {

    this.$store.dispatch("setCurrentPage","dashboard");
    this.$store.dispatch("setCurrentPageName","Dashboard");
    this.$store.dispatch("setPreviousPage","");
    this.getTimesheets(4);
    this.getTimesheets(0);

  },
  computed: {

  },
  methods: {

    getSummary: function(){

      var vm = this;

      var path = process.env.VUE_APP_URL_DASHBOARD_SUMMARY;

      axios.post(path, JSON.stringify({}),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            vm.summary = res.data.data;

          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {

              vm.setError("Failed",err.response.data.data)

            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")

            }
            else {

              vm.setError("Network Error", "Check your network connection and try again")

            }

          })

    },

    getTimesheets: function(status){

      var vm = this;

      var path = process.env.VUE_APP_URL_WORKSHEET_VIEW_LIST;

      axios.post(path, JSON.stringify({status: parseInt(status)}),{
        headers: {
          'api-key': vm.getAuth()
        },
      })
          .then(res => {

            var msg = res.data.data;
            if(parseInt(status) === 4) {

              vm.closedWorksheet = msg;

            } else {

              vm.openWorksheet = msg;

            }

          })
          .catch(err => {

            vm.loading = '';

            console.log('FAILED response '+JSON.stringify(err.response.data.data))

            if (err.response) {

              vm.setError("Failed",err.response.data.data)

            }
            else if (err.request) {

              vm.setError("Network Error", "Check your network connection and try again")

            }
            else {

              vm.setError("Network Error", "Check your network connection and try again")

            }

          })

    },

    viewMembers: function (worksheet){

      this.$router.push({ name: 'worksheetviewmembers', params: {id: worksheet.id, name:worksheet.name} });

    },

  },
}

</script>