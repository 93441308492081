import Home from './components/Home'
import Details from './components/Details'
import Users from './components/Users'
import Presenters from './components/Presenters'
import Participants from './components/Participants'
import Login from './components/Login'
import Signin from './components/Signin'
// import Hookup from "./components/Hookup"
// import HookupPartcipants from "./components/HookupPartcipants"
import Premium from "./components/Premium"

export const routes = [
    {
        path: '/',
        name: 'login',
        component: Login,
    },
    {
        path: '/join',
        name: 'signin',
        component: Signin,
    },
    {
        path: '/dashboard',
        name: 'home',
        component: Home,
        meta: {
            title: 'Wage Pay',
        }
    },
    {
        path: '/details',
        name: 'details',
        component: Details,
    },
    {
        path: '/users',
        name: 'users',
        component: Users,
    },
    {
        path: '/presenters',
        name: 'presenters',
        component: Presenters,
    },
    {
        path: '/participants',
        name: 'participants',
        component: Participants,
    },
    // {
    //     path: '/hookup',
    //     name: 'hookup',
    //     component: Hookup,
    // },
    // {
    //     path: '/hookup-partcipants',
    //     name: 'hookup-partcipants',
    //     component: HookupPartcipants,
    // },
    {
        path: '/premium',
        name: 'premium',
        component: Premium,
    },
    {
        path: '*',
        name: 'details',
        component: Details,
    },
];
