<template>

  <div class="">

    <div class="container-fluid">

      <div class="card custom-card-wrapper">

        <div class="card-body p-0">

          <div class="card-body-header p-2 d-flex justify-content-between border-bottom">

            <div style="cursor: pointer;" @click="reload">
              <i class="bi bi-arrow-clockwise" ></i>&nbsp;
              Refresh
            </div>

            <div id="reportrange" style="cursor: pointer;">
              <i class="bi bi-calendar"></i>&nbsp;
              <span></span> <i class="bi bi-chevron-down"></i>
            </div>

            <div class="input-group blue-input-group mb-0">
              <input type="text" class="form-control" aria-label="Text input with 2 dropdown buttons" v-model="search">
              <button @click="doSearch" class="btn blue-input-group-btn-right btn-outline-primary  font-size-12" type="button" ><i class="bi bi-search"></i></button>
            </div>

          </div>

          <vuetable
              ref="vuetable"
              api-url="request/table"
              :api-mode="apiMode"
              :fields="fields"
              :sort-order="sortOrder"
              :css="css.table"
              pagination-path=""
              :per-page="perPage"
              :append-params="moreParams"
              :http-fetch="fetchMembers"
              :load-on-start="loadOnStart"
              @vuetable:pagination-data="onPaginationData"
              @vuetable:loading="onLoading"
              @vuetable:loaded="onLoaded">

            <div slot="actions-slot" slot-scope="props">
              <div class="btn-group">

                <button type="button" class="btn btn-primary btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                  More
                </button>

                <ul class="dropdown-menu">

                  <li><a class="dropdown-item" @click="createMember(props.rowData)">View More</a></li>

                  <li><a class="dropdown-item" @click="viewMembers(props.rowData)">Reply</a></li>

                </ul>

              </div>
            </div>

            <div slot="sequence-slot" slot-scope="props">
              {{ props.rowIndex + 1}}
            </div>

            <div slot="format-created" slot-scope="props">
              {{ formatDateWithoutYear(props.rowData.created) }}
            </div>

            <div slot="mask-msisdn-slot" slot-scope="props">
              {{ mask(props.rowData.msisdn) }}
            </div>

          </vuetable>

          <vuetable-pagination
              ref="pagination"
              :css="css.pagination"
              @vuetable-pagination:change-page="onChangePage">
          </vuetable-pagination>

        </div>

      </div>

    </div>

  </div>


</template>

<script>

import Vue from 'vue'
import axios from "../services/api";
import Vuetable from 'vuetable-2'

import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
import VuetableFieldSequence from "vuetable-2/src/components/VuetableFieldSequence";
Vue.use(Vuetable);
var moreParams = {};

export default {
  name: 'Details',
  components: {
    Vuetable,
    'vuetable-pagination': VuetablePagination

  },
  data: function (){
    return {
      active_tab: 'account',
      // vuetable
      search: '',
      fields: [
        {
          name: VuetableFieldSequence,
          title: '#',
        },
        {
          name: "format-created",
          title: '<i class="bi bi-calendar"></i> Date',
          sortField: 'transactions.created',
        },
        {
          name: 'shortcode',
          title: 'ShortCode',
          sortField: 'inbox.short_code',
        },
        {
          name: 'mask-msisdn-slot',
          title: 'Phone Number',
          sortField: 'inbox.msisdn',
        },
        {
          name: "message",
          title: 'Message',
          sortField: 'inbox.message',
        },/*
        {
          name: "actions-slot",
          title: '<span class="orange glyphicon glyphicon-calendar"></span> Actions',
        },*/
      ],
      moreParams: moreParams,
      css: {
        table: {
          tableClass: 'table table-striped table-bordered table-hovered',
          loadingClass: 'loading',
          ascendingIcon: 'glyphicon glyphicon-chevron-up',
          descendingIcon: 'glyphicon glyphicon-chevron-down',
          handleIcon: 'glyphicon glyphicon-menu-hamburger',
        },
        pagination: {
          infoClass: 'pull-left',
          wrapperClass: 'vuetable-pagination pull-right',
          activeClass: 'bg-green',
          disabledClass: 'disabled',
          pageClass: 'btn btn-border',
          linkClass: 'btn btn-border',
          icons: {
            first: '',
            prev: '',
            next: '',
            last: '',
          },
        }
      },
      sortOrder: [
        { field: 'inbox.id', direction: 'desc' }
      ],
      perPage: parseInt(10),
      loadOnStart: true,
      apiMode: true,
      loading: ''
    }
  },
  mounted() {

    this.$store.dispatch("setCurrentPage","details");

  },
  computed: {
    client: function (){
      return this.getClient();
    },
  },
  methods: {
    doSearch: function () {
      //console.log("search here", this.search);
      this.moreParams.search = this.search;
      //console.log("moreParams after setting search:", this.moreParams);
      this.$refs.vuetable.refresh();
    },

    dateFormat: function (date){
      return this.dateFormat(date)

    },
    mask: function (msisdn){
      return msisdn;
    },

    mask1: function (msisdn){

      msisdn = ""+msisdn;

      var parts = msisdn.split('');

      if(parts.length > 9) {

        //254726 120 256
        parts[6] = ' x'
        parts[7] = 'x'
        parts[8] = 'x '

      }

      msisdn = parts.join('');

      return msisdn;

    },

    reload: function() {
      this.moreParams.search = '';
      this.$refs.vuetable.refresh();
    },

    onPaginationData: function(paginationData) {

      this.$refs.pagination.setPaginationData(paginationData)
    },

    onChangePage: function(page) {

      this.$refs.vuetable.changePage(page)

    },

    delete: function(rowData) {
      console.log('TO delete '+JSON.stringify(rowData))
    },

    editRow: function(rowData){
      alert("You clicked edit on"+ JSON.stringify(rowData))
    },

    deleteRow: function(rowData){
      alert("You clicked delete on"+ JSON.stringify(rowData))

    },

    onLoading: function() {
      console.log('loading... show your spinner here')

    },

    onLoaded: function() {
      console.log('loaded! .. hide your spinner here')
      console.log('current vuetable data:', this.$refs.vuetable.tableData);
    },

    fetchMembers: function(apiUrl, httpOptions){
      var vm = this;
      var path = process.env.VUE_APP_URL_TRANSACTIONS_VIEW_TABLE;
      return axios.post(path, JSON.stringify(httpOptions.params), {
        headers: {
          'api-key': vm.getAuth()
        },
      })
    },

    formatAmount: function (amount){
      return this.formatStake(amount);

    },

  },
}

</script>
