<template>
  <div id="premium">
    <div class="row m-2">
      <div class="col col-12 px-3 col-lg-3 mt-sm-3">
        <div class="card p-3">
          <div class="heading border-bottom border-primary-subtle">
            <p class="h4 card-title">Subscription Channel</p>
          </div>
          <div class="sms-row" v-for="group in shortcodes" :key="group.id">
            <div
              class="card px-2 my-1"
              @click="viewMore(group)"
              v-bind:class="group.class"
            >
              <div class="sms-content">
                <p class="h6 mt-1">{{ group.name }}</p>
                <div class="d-flex justify-content-between">
                  <p class="fs-6 text text-secondary">
                    {{ group.short_code }} - {{ group.network }}
                  </p>
                  <p class="fs-6 text text-secondary">
                    {{ group.recipients }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col col-12 px-3 col-lg-9 mt-sm-3">
        <div class="card p-3">
          <div class="header mb-4">
            <div class="content-text">
              <p class="h3 card-title text-success">{{ shortcode.name }}</p>
              <div
                class="d-flex flex-column flex-lg-row justify-content-between mb-sm-2"
              >
                <p class="fs-5 text text-secondary">
                  {{ shortcode.recipients }} Subscribers
                </p>
              </div>
            </div>
          </div>

          <div class="nav nav-tabs">
            <li class="nav-item" style="cursor: pointer">
              <a class="nav-link ml-3 active" @click="toggleTab('messages')"
                >Message</a
              >
            </li>
            <li class="nav-item" style="cursor: pointer">
              <a class="nav-link mx-3" @click="toggleTab('subscribers')"
                >Subscribers</a
              >
            </li>
            <li class="nav-item" style="cursor: pointer">
              <a class="nav-link mx-3" @click="toggleTab('reports')">Reports</a>
            </li>
          </div>

          <div
            class="mt-4"
            aria-current="page"
            v-show="currentTab === 'messages'"
          >
            <div class="heading">
              <p class="h6">Messages</p>
            </div>
            <div class="table">
              <vuetable
                ref="vuetable_campaigns"
                :data="campaigns"
                api-url="request/table"
                pagination-path=""
                :load-on-start="loadOnStart"
                :fields="messageColumns"
                :sort-order="messageSortOrder"
                :multi-sort="multiSort"
                :pagination-component="paginationComponent"
                :item-actions="itemActions"
                :append-params="messageParams"
                :per-page="perPage"
                table-class="ui striped celled striped small unstackable table"
                ascending-icon="fa fa-chevron-up"
                descending-icon="fa fa-chevron-down"
                pagination-class=""
                pagination-info-class=""
                :pagination-info-template="PaginationInfoTemplate"
                pagination-info-no-data-template="The requested query returned no result"
                wrapper-class="vuetable-wrapper"
                table-wrapper=".vuetable-wrapper"
                loading-class="vuetable-loading"
                detail-row-transition="expand"
              >
              </vuetable>
            </div>
          </div>

          <div class="mt-4" v-show="currentTab === 'subscribers'">
            <div class="heading d-inline-block">
              <p class="text text-secondary">
                Subscribers {{ shortcode.recipients }}
              </p>
            </div>
            <div class="table">
              <vuetable
                ref="vuetable_subscribers"
                :data="campaign_subsribers"
                api-url="request/table"
                pagination-path=""
                :load-on-start="loadOnStart"
                :fields="subscribersColumns"
                :sort-order="subscribersSortOrder"
                :multi-sort="multiSort"
                :pagination-component="paginationComponent"
                :append-params="subscribersParams"
                :per-page="perPage"
                table-class="ui small striped celled unstackable table"
                ascending-icon="fa fa-chevron-up"
                descending-icon="fa fa-chevron-down"
                pagination-class=""
                pagination-info-class=""
                :pagination-info-template="PaginationInfoTemplate"
                pagination-info-no-data-template="The requested query returned no result"
                wrapper-class="vuetable-wrapper"
                table-wrapper=".vuetable-wrapper"
                loading-class="vuetable-loading"
                detail-row-transition="expand"
                @vuetable:pagination-data="onPaginationData"
              >
                <template v-slot:status="{ rowData }">
                  <span
                    v-if="parseInt(rowData.status) === 1"
                    class="badge bg-success"
                  >
                    Active
                  </span>
                  <span v-else class="badge bg-secondary text-light">
                    Deactivated
                  </span>
                </template>
              </vuetable>
            </div>
          </div>

          <div class="mt-4" v-show="currentTab === 'reports'">
            <div class="heading">
              <p class="h6">Report</p>
            </div>
            <div class="">
              <div class="">
                <ReportTable :items="campaign_reports" :myToken="myToken" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="p-3">
      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Modal title
              </h1>
            </div>
            <div class="modal-body">
              <div class="row p-3">
                <div class="col-12 col-md-4">
                  <label class="h6" for=""> Campaign Title </label>
                  <div class="input-group flex-nowrap">
                    <input
                      type="text"
                      v-model="campaign_name"
                      placeholder="Campaign Name"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <label class="h6" for=""> Send on Date</label>
                  <div class="input-group flex-nowrap">
                    <input
                      type="date"
                      v-model="campaign_name"
                      class="form-control"
                    />
                  </div>
                </div>
                <div class="col-12 col-md-4">
                  <label class="h6" for=""> Send what time </label>
                  <div class="input-group flex-nowrap">
                    <input
                      type="time"
                      v-model="campaign_name"
                      class="form-control"
                    />
                  </div>
                </div>
              </div>
              <div class="row p-3">
                <label class="h6">Message</label>
                <textarea placeholder="Enter Message" rows="3"></textarea>
                <div class="">
                  <small>Max 160 Characters</small>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                cancel
              </button>
              <button type="button" class="btn btn-primary mx-3">
                Send Message
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "../services/api";
import ReportTable from "./reports/ReportTable.vue";
import { Vuetable } from "vuetable-2";
//import VuetablePagination from "vuetable-2/src/components/VuetablePagination";
//import VuetableFieldSequence from "vuetable-2/src/components/VuetableFieldSequence";
import $ from "jquery";

export default {
  name: "#premium",

  data() {
    return {
      //essentials

      recipientsParams: ["service=sms", "id=", "filter=", "start=", "end="],

      token: "",
      selected: "all",
      shortcodes: [],
      shortcode: {},
      selectedShortcodeId: null,
      loading: "",
      reportsRoute: "premium/reports",
      subscribersRoute: "premium/subscribers",
      messageRoute: "premium/campaigns",
      campaign_subsribers: [],
      message: "",
      campaigns: [],
      send_on_date: "",
      send_on_time: "",
      currentTab: "messages",

      //messages

      loadOnStart: false,
      perPage: 10,
      multiSort: true,
      paginationComponent: "vuetable-pagination",
      paginationInfoTemplate: "Displaying {from} to {to} of {total} records ",
      myToken: "",

      //messages table props//
      messageColumns: [
      
        {
          name: "created",
          title: "Date",
          sortField: "sms_subscription_campaign.created",
          titleClass: "table-header",
          dataClass: "table-data",
          callback: "createdAt",
        },
        {
          name: "campaign_name",
          title: "Title",
          sortField: "sms_subscription_campaign.campaign_name",
          titleClass: "table-header",
          dataClass: "table-data",
        },
        {
          name: "message",
          title: "Message",
          titleClass: "table-header",
          dataClass: "table-data",
        },
        {
          name: "__component:message-action",
          title: "Actions",
          titleClass: "table-header",
          dataClass: "table-data center aligned",
        },
      ],
      messageSortOrder: [
        {
          field: "sms_subscription_campaign.id",
          direction: "desc",
        },
      ],
      messageParams: ["route=", "filter=", "start=", "end="],
      search: "",

      //suscribers

      subscribersColumns: [
        {
          name: "created",
          title: "Date Updated",
          sortField: "campaign.created",
          titleClass: "table-header",
          dataClass: "table-data",
          callback: "createdAt",
        },
        {
          name: "msisdn",
          title: "Phone Number",
          sortField: "campaign_subsribers.msisdn",
          titleClass: "table-header",
          dataClass: "table-data",
        },
        {
          name: "status",
          title: "Status",
          titleClass: "table-header",
          dataClass: "table-data",
          sortField: "campaign_subsribers.status",
        },
        {
          name: "__component:subscriber-action",
          title: "Actions",
          titleClass: "table-header",
          dataClass: "table-data center aligned",
        },
      ],
      //subscribers table
      subscribersSortOrder: [],
      subscribersParams: ["service=sms", "route=", "filter=", "start=", "end="],
      //reports

      //recipents

      recipientsSortOrder: [
        {
          field: "sms_subscription_outbox.id",
          direction: "desc",
        },
      ],

      recipientsColumn: [
        {
          name: "__sequence",
          title: "#",
          dataClass: "center aligned",
        },
        {
          name: "created",
          title: "Date Updated",
          sortField: "sms_subscription_outbox_dlr.updated",
          titleClass: "table-header",
          dataClass: "table-data",
          callback: "createdAt",
        },
        {
          name: "msisdn",
          title: "Phone Number",
          sortField: "sms_subscription_outbox.msisdn",
          titleClass: "table-header",
          dataClass: "table-data",
        },
        {
          name: "telco_status_code",
          title: "Status",
          titleClass: "table-header",
          dataClass: "table-data",
          sortField: "sms_subscription_outbox_dlr.telco_status_code",
        },
      ],
    };
  },
  created() {
    this.getToken()
      .then(() => {
        this.init();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  },
  mounted: function () {
    this.init();
    console.log("Data for the table:", this.campaign_subsribers);
  },

  ready: function () {
    this.init();
  },

  methods: {
    init: function () {
      this.fetchMembers();
    },

    getToken() {
      const url = "https://identity-service.intouchvas.io/auth/user/login";
      let data = {
        msisdn: "254790936179",
        password: "Shalom123.",
      };

      return new Promise((resolve, reject) => {
        axios
          .post(url, data)
          .then((response) => {
            this.myToken = response.data.message.token;
            console.log("Status:", response.status);
            resolve();
          })
          .catch((error) => {
            console.error("Error:", error.response.data);
            reject(error);
          });
      });
    },

    toggleTab(tab) {
      this.currentTab = tab;
    },

    fetchMembers: function () {
      const Token = this.myToken;
      let vm = this;

      const payload = {
        service: process.env.VUE_APP_BASE_SMS_SERVICE,
        route: "premium/shortcode",
      };

      const httpConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: Token,
        },
      };
      const endpoint = `${payload.service}/${payload.route}`;

      axios
        .post(endpoint, payload, httpConfig)
        .then((response) => {
          vm.loading = "";
          vm.shortcodes = response.data.message;
          vm.viewMore(vm.shortcodes[0]);
        })
        .catch((error) => {
          console.error(error.reponse.data.status);
        });
    },

    setDefaults: function () {
      if (this.campaign_name.length === 0) {
        this.campaign_name = this.shortcode.start_keywords;
      }
    },

    getCampaigns() {
      const httpConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.myToken,
        },
      };
      const axiosParams = {
        sort: "sms_subscription_campaign.id|desc",
        id: this.shortcode.id,
        per_page: this.perPage,
      };
      axios
        .post(
          "https://sms-service.intouchvas.io/premium/campaigns",
          axiosParams,
          httpConfig
        )
        .then((response) => {
          const messageData = response.data;
          this.campaigns = messageData;
          // console.log(messageData);
        })
        .catch((error) => {
          console.error(error);
        });

      if (this.$refs.vuetable_campaigns) {
        this.loading = "loading";
        this.$refs.vuetable_campaigns.$emit("vuetable:refresh");
      }
    },

    getReports: function () {
      const httpConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.myToken,
        },
      };
      const axiosParams = {
        sort: "sms_subscription_campaign.id|desc",
        id: this.shortcode.id,
        per_page: 5,
      };
      axios
        .post(
          "https://sms-service.intouchvas.io/premium/reports",
          axiosParams,
          httpConfig
        )
        .then((response) => {
          this.campaign_reports = response.data.data;
          // console.log(this.campaign_reports)
        })
        .catch((error) => {
          console.log(error);
          this.loading = "";
        });
    },

    getSubscribers: function () {
      const httpConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.myToken,
        },
      };
      const axiosParams = {
        sort: "",
        id: this.shortcode.id,
        per_page: 10,
      };

      axios
        .post(
          "https://sms-service.intouchvas.io/premium/subscribers",
          axiosParams,
          httpConfig
        )
        .then((response) => {
          this.campaign_subsribers = response.data;
          // console.log(response.data);
          this.loading = "";
        })
        .catch((error) => {
          console.log(error);
          this.loading = "";
        });
    },

    viewMore: function (data) {
      let vm = this;

      vm.shortcode = data;

      $.each(vm.shortcodes, function (k, v) {
        if (parseInt(v.id) === parseInt(data.id)) {
          v.class = "active";
        } else {
          v.class = "";
        }

        vm.shortcodes[k] = v;
      });

      this.getCampaigns();
      this.getSubscribers();
      this.getReports();
      this.setDefaults();
    },

    createdAt(value) {
      return this.formatTime(value);
    },

    getStatus: function (data) {
      return parseInt(data) === 1
        ? '<span class="badge bg-success"><i class="bi bi-check"></i> Active</span>'
        : '<span class="badge bg-warning text-dark"><i class="bi bi-x"></i> Deactivated</span>';
    },

    getMessageStatus: function (data) {
      return parseInt(data) === 1
        ? '<span class="badge bg-success"><i class="bi bi-check"></i> Active</span>'
        : '<span class="badge bg-warning text-dark"><i class="bi bi-x"></i> Deactivated</span>';
    },
  },
  components: {
    Vuetable,
    ReportTable,
    //VuetablePagination,
    //VuetableFieldSequence,
  },

  computed: {
    // formatTime(value) {
    //   if (value === "" || !value) {
    //     return moment().format("Do MMM h:mm a");
    //   }
    //   value = value.replace("T", " ");
    //   value = value.replace("Z", "");
    //   return moment(value).format("Do MMM h:mm a");
    // },
  },
};
</script>
<style></style>
