<template>
  <div id="mytable">
    <div class="mb-5" v-show="show_recipients">
      <button class="btn btn-primary btn-sm" @click="goBack">
        <i class="icon backward"></i>
        Back to Reports
      </button>
    </div>

    <div id="reports" v-show="!show_recipients">
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Title</th>
            <th>Stats</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="report in items" :key="report.id">
            <td class="">
              <span class="d-block">{{ report.campaign_name }}</span>
              <span class="d-block" v-text="createdAt(report.created)"></span>
            </td>
            <td>
              <div class="d-flex">
                <div class="d-flex justify-content-between">
                  <div class="d-block mx-4 mb-4">
                    <div class="value">{{ report.recipients }}</div>
                    <div class="label">Total</div>
                  </div>
                  <div class="d-block text-success mx-4 mb-4">
                    <div class="value">{{ report.successful }}</div>
                    <div class="label">Delivered</div>
                  </div>
                  <div class="d-block text-secondary mx-4 mb-4">
                    <div class="value">{{ report.pending }}</div>
                    <div class="label">Pending</div>
                  </div>
                  <div class="d-block text-danger mx-4 mb-4">
                    <div class="value">{{ report.failed }}</div>
                    <div class="label">Failed</div>
                  </div>
                </div>
              </div>
              <div class="progress">
                <div
                  class="progress-bar bg-info"
                  role="progressbar"
                  :style="{ width: getSuccessRate(report) + '%' }"
                  :aria-valuenow="getSuccessRate(report)"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                  {{ getSuccessRate(report) }}%
                </div>
              </div>
            </td>
            <td>
              <button
                class="btn btn-primary btn-sm"
                @click="getRecipients((campaignId = report.id))"
              >
                <span class="text"><i class="icon eye"></i> View</span>
              </button>
            </td>
          </tr>
        </tbody>
        <!-- <paginate
          :page-count="reportTotalPages"
          :click-handler="reportPageChange"
          :prev-text="'Prev'"
          :next-text="'Next'"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :prev-class="'page-item'"
          :next-class="'page-item'"
          :prev-link-class="'page-link'"
          :next-link-class="'page-link'"
        ></paginate> -->
      </table>
    </div>
    <div id="recipients" v-show="show_recipients">
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th>Date Updated</th>
            <th>Phone Number</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in campaign_recipients" :key="item.id">
            <td>{{ createdAt(item.created) }}</td>
            <td>{{ item.msisdn }}</td>
            <td>{{ item.telco_status_code }}</td>
          </tr>
        </tbody>
        <!-- <paginate
          :page-count="recipientTotalPages"
          :click-handler="recipientPageChange"
          :prev-text="'Prev'"
          :next-text="'Next'"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :prev-class="'page-item'"
          :next-class="'page-item'"
          :prev-link-class="'page-link'"
          :next-link-class="'page-link'"
        ></paginate> -->
      </table>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "../../services/api";
// import Paginate from "vuejs-paginate";

export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    myToken:{
      type: String,
      required: true
    }
  },

  components: {
    // Paginate,
  },

  data() {
    return {
      reportData: [], // Array to store report data
      recipientData: [], // Array to store recipient data
      pageSize: 5, // Number of items per page
      reportCurrentPage: 1, // Current page for report table
      recipientCurrentPage: 1, // Current page for recipient table
      show_recipients: false,
      campaignId: "",
      campaign_recipients: [],
    };
  },

  methods: {
    getSuccessRate(report) {
      if (report.recipients === 0) {
        return 0;
      } else {
        return Math.min(
          100,
          Math.max(0, (report.successful / report.recipients) * 100)
        );
      }
    },

    formatTime(value) {
      if (value === "" || !value) {
        return moment().format("Do MMM h:mm a");
      }
      value = value.replace("T", " ");
      value = value.replace("Z", "");
      return moment(value).format("Do MMM h:mm a");
    },

    createdAt(value) {
      return this.formatTime(value);
    },

    getRecipients: function (campaignId, page = 1) {
      this.show_recipients = true;
      const httpConfig = {
        headers: {
          "Content-Type": "application/json",
          Authorization: this.myToken,
        },
      };
      const axiosParams = {
        id: campaignId,
        sort: "sms_subscription_campaign.id|desc",
        per_page: this.pageSize,
      };

      axios
        .post(
          "https://sms-service.intouchvas.io/premium/campaign/recipient",
          axiosParams,
          httpConfig
        )
        .then((response) => {
          this.campaign_recipients = response.data.data;
          this.currentPage = page;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goBack() {
      this.show_recipients = false;
    },

    reportPageChange(page) {
      this.reportCurrentPage = page;
    },
    recipientPageChange(page) {
      this.recipientCurrentPage = page;
    },
  },

  computed: {
    reportTotalPages() {
      return Math.ceil(this.items.length / this.pageSize);
    },
    recipientTotalPages() {
      return Math.ceil(this.campaign_recipients.length / this.pageSize);
    },
    paginatedReportData() {
      const startIndex = (this.reportCurrentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.items.slice(startIndex, endIndex);
    },
    paginatedRecipientData() {
      const startIndex = (this.recipientCurrentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.campaign_recipients.slice(startIndex, endIndex);
    },
  },
};
</script>
