<template>
  <body id="app">

    <section v-if="current_page === 'login'" class="main-wrapper d-flex justify-content-center text-center">

      <div id="body-wrapper-1">

        <router-view></router-view>

        <div id="footer-1" class="text-center">
          <a href="">Contact</a> <a href="">Customer Support </a> <span>&copy; <span v-text="year"></span> Bulk Payment.
            All Rights Reserved</span>
        </div>

      </div>

    </section>

    <section v-else class="main-wrapper d-flex justify-content-start">

      <div id="menu-wrapper">

        <div class="menu-header  text-light text-center font-weight-bold py-3 px-3 pr-1 d-flex justify-content-between">

          <span id="title">Intouchvas</span>

          <span @click="toggleMenu()" class="toggle-btn bg-transparent text-light">
            <i class="bi bi-arrow-right  d-none"></i> <i class="bi bi-arrow-left"></i>
          </span>

          <span @click="hideSideBar()" class="hide-btn bg-transparent text-light">
            <i class="bi bi-arrow-left"></i>
          </span>

        </div>

        <div class="menu-items">

          <a class="">
            <div class="menu-item px-3 py-2 " v-bind:class="activeClass('dashboard')">
              <img src="/img/dashboard_active.svg" class="menu-item-icon ">
              <span class="tohide">Dashboard</span>
            </div>
          </a>

          <a class="" @click="goTo('details')">
            <div class="menu-item px-3 py-2 " v-bind:class="activeClass('details')">
              <img src="/img/business.svg" class="menu-item-icon ">
              <span class="tohide">SMS</span>
            </div>
          </a>

          <a v-if="parseInt(profile.role_id) === 1" class="" @click="goTo('users')">
            <div class="menu-item px-3 py-2 " v-bind:class="activeClass('users')">
              <img src="/img/user.svg" class="menu-item-icon ">
              <span class="tohide">User</span>
            </div>
          </a>

          <a v-if="parseInt(profile.role_id) === 1" class="" @click="goTo('presenters')">
            <div class="menu-item px-3 py-2 " v-bind:class="activeClass('presenters')">
              <img src="/img/user.svg" class="menu-item-icon ">
              <span class="tohide">Presenters</span>
            </div>
          </a>

          <a class="" @click="goTo('participants')">
            <div class="menu-item px-3 py-2 " v-bind:class="activeClass('participants')">
              <img src="/img/worksheet.svg" class="menu-item-icon ">
              <span class="tohide">Participants</span>
            </div>
          </a>
          <!-- <a class="" @click="goTo('hookup')">
            <div class="menu-item px-3 py-2 " v-bind:class="activeClass('hookup')">
              <img src="/img/worksheet.svg" class="menu-item-icon ">
              <span class="tohide">Hook Ups</span>
            </div>
          </a>
          <a class="" @click="goTo('hookup-partcipants')">
            <div class="menu-item px-3 py-2 " v-bind:class="activeClass('hookup-partcipants')">
              <img src="/img/worksheet.svg" class="menu-item-icon ">
              <span class="tohide">Hookup Partcipants</span>
            </div>
          </a> -->
          <!-- <a  class="" @click="goTo('gaming')">
            <div class="menu-item px-3 py-2 "  v-bind:class="activeClass('gaming')">
              <img src="/img/worksheet.svg" class="menu-item-icon ">
              <span class="tohide">Gaming</span>
            </div>
          </a> -->
          <a class="" @click="goTo('premium')">
            <div class="menu-item px-3 py-2 "  v-bind:class="activeClass('premium')">
              <img src="/img/user.svg" class="menu-item-icon ">
              <span class="tohide">premium SMS</span>
            </div>
          </a>
          

        </div>

        <div class="px-3 log-out">

          <a @click="logout" class="logout-btn px-0 py-0 form-control bg-transparent">
            <div class="text-green px-2 p text-center py-1 ">
              <img src="/img/loguot.svg" class="menu-item-icon mr-0">
              <span class="tohide ml-2">Logout</span>
            </div>
          </a>

        </div>

      </div>

      <div id="body-wrapper">

        <div class="main-header d-flex justify-content-end line-height-1 p-2 mb-2">

          <button type="button" data-bs-toggle="modal" data-bs-target="#make-payment"
            class="btn btn-primary btn-sm position-relative">
            Live SMS
            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger"> <span
                v-text="sms.length"></span> + <span class="visually-hidden">unread messages</span></span>
          </button>

          <a href="" class="icon-btn px-2"><img class="icon-btn" src="/img/bell.svg"></a>
          <a href="" class="icon-btn" data-bs-toggle="dropdown" aria-expanded="false"><img class="icon-btn"
              src="/img/profile.svg"></a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#changepassword">Change Password</a></li>
            <li><a class="dropdown-item" @click="logout">Logout</a></li>
          </ul>
          <span class="menu-toggle" @click="toggleSideBar()">
            <i class="bi bi-list"></i>
          </span>
        </div>

        <router-view></router-view>

        <div id="footer" class="text-center">
          <a href="">Contact</a> <a href="">Customer Support </a> <span>&copy; <span v-text="year"></span> InTouchVAS. All
            Rights Reserved</span>
        </div>

      </div>

    </section>

    <div class="modal fade" id="changepassword" tabindex="-1" aria-labelledby="change-password" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" v-bind:class="loading">
        <div class="modal-content">

          <div class="modal-header">
            <h5 class="modal-title" id="change-password">Change Password</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>

          <div class="modal-body">

            <form>

              <div v-show="error_message.length > 0" class="alert alert-danger alert-dismissible fade show" role="alert"
                style="font-size: 0.9em;text-align: left;">
                <span v-text="error_message"></span>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>

              <div v-show="success_message.length > 0" class="alert alert-success alert-dismissible fade show"
                role="alert" style="font-size: 0.9em;text-align: left;">
                <span v-text="success_message"></span>
                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
              </div>

              <div class="form-floating mb-3">
                <input v-model="current_password" type="password" class="form-control form-control-lg"
                  id="current_password" placeholder="Input your current password">
                <label for="current_password" class="form-label">Current Password</label>
              </div>

              <div class="form-floating mb-3">
                <input v-model="password" type="password" class="form-control form-control-lg" id="password"
                  placeholder="Input your new password">
                <label for="password" class="form-label">Password</label>
              </div>

              <div class="form-floating mb-3">
                <input v-model="password1" type="password" class="form-control form-control-lg" id="password1"
                  placeholder="Repeat your new password">
                <label for="password1" class="form-label">Repeat Password</label>
              </div>

              <div class="modal-footer-copy">
                <button type="button" class="btn btn-outline-secondary btn-sm mr-2" id="close-changepassword"
                  data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary btn-sm shadow" @click="changePassword">Change
                  Password</button>
              </div>

            </form>

          </div>

        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Top Up Account</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <form class="custom-form">
              <div class="input-wrapper mb-2">
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">Select Payment Method</label>
                  <select class="form-select" aria-label="Default select example">
                    <option value="1">MPESA</option>
                  </select>
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">Amount to top up</label>
                  <input type="text" class="form-control">
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">Your MPESA Phone No</label>
                  <input type="text" class="form-control">
                </div>
              </div>


              <div class="modal-footer-copy">
                <button type="button" class="btn btn-outline-secondary btn-sm mr-2" data-bs-dismiss="modal">Close</button>
                <button type="button" class="btn btn-primary btn-sm shadow">Topup Account</button>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>

    <div class="modal fade" id="make-payment" tabindex="-1" aria-labelledby="make-payment-label" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" v-bind:class="loading">
        <div class="modal-content">

          <div class="modal-header">
            <h5 class="modal-title" id="make-payment-label">Live SMS</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>

          <div class="modal-body">

            <div class="card" v-show="currentSMS !== false">
              <div class="card-header">
                From <strong v-text="mask(currentSMS.msisdn)"></strong>
              </div>
              <div class="card-body">
                <p class="card-text" v-text="currentSMS.message"></p>
                <hr />
                <div class="d-grid gap-2 d-md-flex justify-content-md-end">
                  <button class="btn btn-primary me-md-2 btn-sm" type="button" @click="getNextSMS">Next</button>
                  <button class="btn btn-success btn-sm" type="button" @click="getLastSMS">Move To Last</button>
                  <!--                  <button class="btn btn-danger btn-sm" type="button" @click="clearAllSMS">Dismiss All</button>-->
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

    <span data-bs-toggle="modal" data-bs-target="#card-iframe" id="show-card-iframe"></span>

    <div class="modal fade" id="card-iframe" tabindex="-1" aria-labelledby="card-iframe-label" aria-hidden="true">

      <div class="modal-dialog modal-lg" style="min-height: 700px">

        <div class="modal-content">

          <div class="modal-body">
            <iframe id="iframe" style="width: 100%;min-height: 700px;border: 0;"></iframe>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" id="cloe-card-iframe" data-bs-dismiss="modal">Close</button>
          </div>

        </div>

      </div>

    </div>

  </body>
</template>

<script>

import mqtt from "mqtt";
import axios from "./services/api";

export default {
  name: 'app',
  components: {

  },
  mounted: function () {

    var vm = this;
    this.EventBus.$on('init:mqtt', function () {

      console.log('Wants Inititalize MQTT');

      if (vm.mqttClient !== false) {

        vm.mqttClient.end();
        vm.mqttClient = false;
      }

      vm.myProfile = vm.getProfile();
      vm.initMqtt();

    });

    vm.initMqtt();

    var params = this.getURLParameters();
    var utm_source = params.utm_source ? params.utm_source : ''
    var utm_medium = params.utm_medium ? params.utm_medium : ''
    var utm_campaign = params.utm_campaign ? params.utm_campaign : ''
    var referrer = document.referrer
    if (utm_source.length > 0) {

      this.setValue('utm_source', utm_source)
    }

    if (utm_medium.length > 0) {

      this.setValue('utm_medium', utm_medium)
    }

    if (utm_campaign.length > 0) {

      this.setValue('utm_campaign', utm_campaign)
    }

    if (referrer.length > 0) {

      this.setValue('referrer', referrer)

    }

    this.clearAllSMS();

    console.log(JSON.stringify(this.profile, undefined, 2))


  },
  computed: {

    bal: function () {

      return this.formatCurrency(this.client.balance);

    },
    full_name: function () {

      if (this.profile === undefined) {

        return ""
      }

      if (this.profile.f1 === undefined || this.profile.f1.length === 0) {

        return ms;

      }

      var fn = this.profile.f1;
      var mn = this.profile.f2;
      var ln = this.profile.f3;
      var ms = this.getUIValue(this.profile.m);
      return fn + ' ' + mn + ' ' + ln + ' - ' + ms;

    },

    profile: function () {

      return this.getProfile();

    },

    current_page: function () {

      return this.$store.state.current_page;

    },
    current_page_name: function () {

      return this.$store.state.current_page_name;

    },
    previous_page: function () {

      return this.$store.state.previous_page;

    },
    sms: function () {

      return this.$store.state.sms;

    },
    currentSMS: function () {

      let sm = this.$store.state.sms

      if (sm && sm.length > 0) {

        return this.$store.state.sms[0]

      }

      return false

    },

    client: function () {

      return this.getClient();
    },
  },
  watch: {

    current_page: function () {

      this.getPrevioudPage();
    }

  },
  methods: {

    mask: function (msisdn) {

      msisdn = "" + msisdn;

      var parts = msisdn.split('');

      if (parts.length > 9) {

        parts[6] = ' x'
        parts[7] = 'x'
        parts[8] = 'x '

      }

      msisdn = parts.join('');

      return msisdn;

    },

    getNextSMS: function () {

      let sm = this.$store.state.sms

      if (sm && sm.length > 0) {

        sm.shift();

      } else {

        sm = []

      }

      this.$store.dispatch("setSMS", sm);

    },

    getLastSMS: function () {

      let sm = this.$store.state.sms

      if (sm && sm.length > 0) {

        sm = []
        sm.push(this.$store.state.sms[sm.length - 1]);

      } else {

        sm = []

      }

      this.$store.dispatch("setSMS", sm);

    },

    clearAllSMS: function () {

      this.$store.dispatch("setSMS", []);

    },

    getPrevioudPage: function () {

      var vm = this;
      var name = this.previous_page;
      var pg = vm.pages[0];
      vm.jQuery.each(vm.pages, function (k, v) {

        if (v.route === name) {

          pg = v;
        }
      });
      this.prev_page = pg;
    },

    activeClass: function (page) {

      return page == this.current_page ? 'active' : '';

    },

    toggleMenu: function () {

      var menuElements = document.getElementsByClassName('tohide');
      var toggleIcon = document.getElementsByClassName('bi-arrow-left')[0];
      var toggleIcon2 = document.getElementsByClassName('bi-arrow-right')[0];

      for (let i = 0; i < menuElements.length; i++) {

        if (menuElements[i].classList.contains('d-none')) {

          menuElements[i].classList.remove('d-none')

        } else {

          menuElements[i].classList.add('d-none')
        }
      }

      if (document.getElementById("title").innerHTML === 'BP') {

        document.getElementById("title").innerHTML = 'BULK PAYMENT'
        document.getElementById('menu-wrapper').style.width = '200px';

      } else {

        document.getElementById("title").innerHTML = 'BP'
        document.getElementById('menu-wrapper').style.width = 'auto';

      }

      if (toggleIcon.classList.contains('d-none')) {

        toggleIcon.classList.remove('d-none');
        toggleIcon2.classList.add('d-none')

      } else {

        toggleIcon2.classList.remove('d-none');
        toggleIcon.classList.add('d-none');

      }

      // document.getElementById('menu-wrapper').style.width = '';
      // document.getElementById('body-wrapper').style.width = 'calc(100% - '+
    },

    toggleSideBar: function () {

      var sidebar = document.getElementById('menu-wrapper');
      sidebar.style.display = 'block';

      if (sidebar.classList.contains('d-none')) {
        sidebar.classList.remove('d-none');
      }

    },

    hideSideBar: function () {

      var sidebar = document.getElementById('menu-wrapper');
      sidebar.style.display = 'none';

    },

    isActive: function (page) {

      return this.current_page === page ? 'active' : '';

    },

    setActive: function (menu) {

      this.current_menu = menu;

    },

    initMqtt: function () {

      var endpoint = process.env.VUE_APP_MQTT_HOST;
      var vm = this;

      if (this.mqttClient !== false) {

        this.mqttClient.end();

      }

      // connection option

      const options = {
        clean: true, // retain session
        connectTimeout: 4000, // Timeout period
        // Authentication information
        clientId: this.getClientID(),
        username: process.env.VUE_APP_MQTT_USERNAME,
        password: process.env.VUE_APP_MQTT_PASSWORD,
      }

      var client = mqtt.connect(endpoint, options);

      client.on('connect', function () {

        var profileTopic = 'topic/profile';

        let prof = vm.getProfile();

        if (prof && prof.short_code) {

          profileTopic = 'topic/' + prof.short_code;

        }

        client.subscribe(profileTopic, function (err) {
          if (!err) {

            console.log('subscribed to topic ' + profileTopic);

          }

        });

      })

      client.on('message', function (topic, msg) {

        // message is Buffer
        var payload = JSON.parse(msg.toString());

        let sm = vm.$store.state.sms

        if (!sm || sm.length === 0) {

          sm = []

        }

        if (!Array.isArray(sm)) {

          let tn = [];
          tn.push(sm)
          vm.$store.dispatch("setSMS", tn)

        } else {

          sm.push(payload)
          vm.$store.dispatch("setSMS", sm)

        }

        console.log("New SMS ===> " + JSON.stringify(payload));

      })

      this.mqttClient = client;

    },

    dismiss: function () {

      console.log('DISMISS MENU');
      var modal = document.getElementById("menu-modal");
      modal.style.display = "none";

    },

    getKey: function (index) {

      return Math.random().toString(10).replace('0.', 'sport-' + index + '-');

    },

    setAmount: function (amount) {

      this.amount = amount
      this.deposit();

    },

    showDeposit: function () {

      document.getElementById("deposit-init").click();

    },

    changePassword: function () {

      this.error_message = '';
      this.success_message = '';

      if (this.current_password.length < 1) {

        this.error_message = "Please enter current password";
        return;

      }

      if (this.password.length < 4) {

        this.error_message = "Please enter a password of more than 4 characters";
        return;

      }

      if (this.password !== this.password1) {

        this.error_message = "Password does not match";
        return;

      }

      this.loading = 'loading';

      var vm = this;

      var path = process.env.VUE_APP_URL_CHANGE_PASSWORD;

      axios.post(path, JSON.stringify({
        current_password: this.current_password,
        password: this.password
      }), {
        headers: {
          'api-key': vm.getAuth()
        },
      })
        .then(res => {

          vm.loading = '';
          var msg = res.data.data;
          vm.error_message = '';
          vm.success_message = msg;
          vm.closeModal('changepassword');
          vm.$swal.fire(
            'Updated',
            msg,
            'success'
          )

        })
        .catch(err => {

          vm.loading = '';

          if (err.response) {

            console.log(JSON.stringify(err.response, undefined, 2))
            vm.error_message = err.response.data.data;

          }
          else if (err.request) {

            console.log(JSON.stringify(err.request, undefined, 2))
            vm.error_message = "Check your network connection and try again";

          }
          else if (err.message) {

            console.log(JSON.stringify(err.message, undefined, 2))
            vm.error_message = err.message;

          }
          else {

            vm.error_message = "Check your network connection and try again";

          }

        })

    },

    cardPayment: function () {

      var vm = this;
      vm.closeModal('make-payment');

      document.getElementById('show-card-iframe').click();

      var url = process.env.VUE_APP_BASE_URL + process.env.VUE_APP_URL_TOPUPS_INITIATE_CARD_PAYMENT

      this.process_card_payment = true;
      this.SMESPAY.setAddress('Wangige');
      this.SMESPAY.setCity('Nairobi');
      this.SMESPAY.setCountry('KE');
      this.SMESPAY.setAmount(this.topup_amount);
      this.SMESPAY.setEmail(vm.profile.email);
      this.SMESPAY.setReference(vm.client.account);
      this.SMESPAY.setFirstName(vm.profile.first_name);
      this.SMESPAY.setLastName(vm.profile.last_name);
      this.SMESPAY.setApiKey(vm.getAuth());
      this.SMESPAY.setTransactionType('sale');
      this.SMESPAY.setURL(url);
      this.SMESPAY.pay();

    },

    mpesaPayment: function () {

      var vm = this;
      this.error_message = '';
      this.success_message = '';

      if (parseInt(this.topup_amount) < 1) {

        this.error_message = "Invalid top up amount";
        return;

      }

      if (parseInt(this.msisdn) < 1) {

        this.error_message = "Invalid top up Mobile Number";
        return;

      }

      this.loading = 'loading';

      var path = process.env.VUE_APP_URL_TOPUPS_INITIATE_MPESA_PAYMENT;

      axios.post(path, JSON.stringify({
        amount: this.topup_amount,
        msisdn: this.msisdn
      }), {
        headers: {
          'api-key': vm.getAuth()
        },
      })
        .then(res => {

          vm.loading = '';
          var msg = res.data.data;
          vm.error_message = '';
          vm.success_message = msg;
          vm.closeModal('make-payment');
          vm.$swal.fire(
            'Updated',
            msg,
            'success'
          )

        })
        .catch(err => {

          vm.loading = '';

          if (err.response) {

            console.log(JSON.stringify(err.response, undefined, 2))
            vm.error_message = err.response.data.data;

          }
          else if (err.request) {

            console.log(JSON.stringify(err.request, undefined, 2))
            vm.error_message = "Check your network connection and try again";

          }
          else if (err.message) {

            console.log(JSON.stringify(err.message, undefined, 2))
            vm.error_message = err.message;

          }
          else {

            vm.error_message = "Check your network connection and try again";

          }

        })

    },

    isSelectedMpesaMethod: function (name) {

      return this.mpesa_method === name ? 'active' : '';

    },
    setMpesaMethod: function (name) {

      this.mpesa_method = name;
    }

  },
  data: function () {
    return {
      currentSM: false,
      current_menu: '',
      amount: 49,
      iconSize: 24,
      iconBigSize: 30,
      mqttClient: false,
      myProfile: this.getProfile(),
      loading: '',
      message: 'Please enter amount you want to deposit',
      year: new Date().getFullYear(),
      prev_page: {},
      pages: [
        {
          route: 'dashboard',
          name: 'Dashboard',
          img: '/img/dashboard_active.svg'
        },
        {
          route: 'groups',
          name: 'Payroll',
          img: '/img/payment_groups.svg'
        },
        {
          route: 'worksheets',
          name: 'Timesheet',
          img: '/img/worksheet.svg'
        },
        {
          route: 'payments',
          name: 'Payment',
          img: '/img/payment_groups.svg'
        },
        {
          route: 'communication',
          name: 'Communications',
          img: '/img/communications.svg'
        },
        {
          route: 'approvals',
          name: 'Approvals',
          img: '/img/business.svg'
        },
        {
          route: 'user',
          name: 'User Management',
          img: '/img/user.svg'
        },
        {
          route: 'setting',
          name: 'Settings',
          img: '/img/settings.svg'
        }
      ],
      password: '',
      password1: '',
      current_password: '',
      code: '',
      success_message: '',
      error_message: '',
      process_card_payment: false,
      topup_method: 'mpesa',
      topup_amount: 0,
      msisdn: '',
      mpesa_method: 'checkout',
    }
  },
  beforeDestroy: function () {

    console.log("beforeDestroy")
    if (this.mqttClient !== false) {

      this.mqttClient.end();
      this.mqttClient = false;

    }

  },
  destroyed: function () {

    console.log("destroyed")
    if (this.mqttClient !== false) {

      this.mqttClient.end();
      this.mqttClient = false;

    }

  }
}
</script>